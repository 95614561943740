import React from "react";
import Layout from "../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FiPlus as PlusIcon } from "react-icons/fi";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Jobs.types";
import { Link as RouterLink } from "react-router-dom";
import { getAddressCopy } from "../../utils/Job/getAddressCopy";
import { getScheduleCopy } from "../../utils/Job/getScheduleCopy";
import { getTagColor } from "../../utils/Job/getTagColor";
import { getJobTypeCopy } from "../../utils/Job/getJobTypeCopy";
import { Pagination } from "../../components/Pagination";
import { PersonName } from "../../components/PersonName";
import { PropertyIcon } from "./components/PropertyIcon";
import FullPageContent from "../../components/FullPageContent";
import { getTagColorByStatus } from "../../utils/Job/getTagColorByStatus";

export const JobsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.jobs;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    jobsData,
    statics,
    pagination,
    onClickUser,
    onClickJob,
    onAddNewJob,
  } = props;
  const {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
  } = pagination;

  const { jobs } = jobsData || {};

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.page)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Flex flex={1} flexDirection="column">
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button
              colorScheme="blue"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={onAddNewJob}
            >
              {t<string>(screen.add)}
            </Button>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}

          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t<string>(screen.table.property)}</Th>
                    <Th>{t<string>(screen.table.jobType)}</Th>
                    <Th>{t<string>(screen.table.schedule)}</Th>
                    <Th>{t<string>(screen.table.contractor)}</Th>
                    <Th>{t<string>(screen.table.price)}</Th>
                    <Th>{t<string>(screen.table.status)}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(jobs || []).map((job) => (
                    <Tr
                      key={job.jobId}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={onClickJob(job.jobId)}
                    >
                      <Td fontWeight={500}>
                        <Flex alignItems="center" width="250px">
                          <PropertyIcon propertyUse={job.propertyUse} />
                          <Flex ml={2} whiteSpace="break-spaces">
                            {getAddressCopy(job)}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td>
                        {job.jobTypeId && (
                          <Tag colorScheme={getTagColor(job.jobTypeId)}>
                            <Text size="12px">
                              {getJobTypeCopy(job, statics).jobType}
                            </Text>
                          </Tag>
                        )}
                      </Td>
                      <Td>{getScheduleCopy(job, statics)}</Td>
                      <Td onClick={onClickUser(+(job.contractor?.userId ?? 0))}>
                        {job.contractor ? (
                          <PersonName
                            email={job.contractor?.email}
                            firstName={job.contractor?.firstName}
                            lastName={job.contractor?.lastName}
                            profilePicUrl={job.contractor?.profilePicUrl}
                          />
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td>${job.totalAmountPaid}</Td>
                      <Td>
                        <Tag
                          borderRadius="sm"
                          colorScheme={getTagColorByStatus(job.jobStatus)}
                          fontWeight={600}
                          variant="solid"
                        >
                          {job.jobStatus.toUpperCase()}
                        </Tag>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(jobs || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noJobs)}</Text>
            </Flex>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
          itemsPerPage={itemsPerPage}
          mt={4}
          totalPages={totalPages}
          onChangeItemsPerPage={onChangeItemsPerPage}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      </FullPageContent>
    </Layout>
  );
};
