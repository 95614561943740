import {
  Flex,
  Image,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
} from "@chakra-ui/react";
import ReportImg from "../../assets/report.png";
import PdficonImage from "../../assets/pdficon.png";
import React, { useState } from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { TJob } from "../../../../api/modules/Jobs/types";
import { ReportType } from "../../Job.types";
import { getFile } from "../../../../api/client/client";
import { Endpoints } from "../../../../constants/endpoints";
import { useToast } from "@chakra-ui/react";

type Props = {
  jobId?: number;
  status?: string;
  job?: TJob;
};
export const JobReport = (props: Props) => {
  const screen = translations.screens.job;
  const { jobId, status, job } = props;
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  if (!jobId || !status) return null;

  const generatedReports = job?.generatedReports;
  const isReportAvailable = status.toLowerCase() === "completed";
  const descriptionVisible = [
    "draft",
    "available",
    "accepted",
    "in progress",
    "in-progress",
    "paid",
  ].includes(status.toLowerCase());

  const getReportTypeName = (type: ReportType): string => {
    switch (type) {
      case ReportType.SepticFacts:
        return "SepticFax Report";
      case ReportType.Title5Inspection:
        return "Title 5 Inspection Report";
      case ReportType.Pumpout:
        return "System Pumping Report";
      default:
        return "";
    }
  };

  let downloadedFileRes;
  const openPDFInNewTab = (url: string) => {
    const newTab = window.open();
    if (newTab) {
      newTab.location.href = url;
    }
  };
  const onClickJob = (type: ReportType) => async () => {
    switch (type) {
      case ReportType.SepticFacts:
        setIsLoading(true); // = true;
        downloadedFileRes = await getFile(
          Endpoints.getSepticFactsReport(jobId)
        );
        if (downloadedFileRes) {
          const url = window.URL.createObjectURL(downloadedFileRes);
          openPDFInNewTab(url);
        } else {
          toast({
            title: "Error",
            description: t<string>(
              translations.errors.septic_facts_report_error
            ),
            status: "error",
            position: "top-right",
          });
          console.error("Error: Blob is null or empty.");
        }
        setIsLoading(false);
        break;
      case ReportType.Title5Inspection:
        setIsLoading(true); // = true;
        downloadedFileRes = await getFile(
          Endpoints.getTitle5InspectionReport(jobId)
        );
        if (downloadedFileRes) {
          const url = window.URL.createObjectURL(downloadedFileRes);
          openPDFInNewTab(url);
        } else {
          toast({
            title: "Error",
            description: t<string>(
              translations.errors.title5_inspection_report_error
            ),
            status: "error",
            position: "top-right",
          });
          console.error("Error: Blob is null or empty.");
        }
        setIsLoading(false);
        break;
      case ReportType.Pumpout:
        setIsLoading(true); // = true;
        downloadedFileRes = await getFile(
          Endpoints.getSystemPumpingReport(jobId)
        );
        if (downloadedFileRes) {
          const url = window.URL.createObjectURL(downloadedFileRes);
          openPDFInNewTab(url);
        } else {
          toast({
            title: "Error",
            description: t<string>(
              translations.errors.system_pumping_report_error
            ),
            status: "error",
            position: "top-right",
          });
          console.error("Error: Blob is null or empty.");
        }
        setIsLoading(false);
        break;
    }
  };

  return (
    <Flex
      alignItems="normal"
      flexDirection="column"
      justifyContent="stretch"
      textAlign="center"
      width="100%"
    >
      {!isReportAvailable && (
        <>
          <br />
          <br />
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="stretch"
            textAlign="center"
            width="100%"
          >
            <Image src={ReportImg} width="150px" />
          </Flex>
          <br />
          <Text fontWeight="600" size="xl">
            {t<string>(screen.noReport)}
          </Text>
          {descriptionVisible && (
            <Text>{t<string>(screen.noReportDescription)}</Text>
          )}
        </>
      )}
      {isReportAvailable && (
        <TableContainer>
          {isLoading && (
            <Flex
              left="50%"
              position="fixed"
              top="53%"
              transform="translate(-50%, -50%)"
              zIndex="9999"
            >
              <Spinner />
            </Flex>
          )}
          <Table>
            <Thead>
              <Tr>
                <Th>{t<string>(screen.reportTable.report)}</Th>
                <Th>{t<string>(screen.reportTable.address)}</Th>
                <Th>{t<string>(screen.reportTable.date)}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {generatedReports?.map(
                ({
                  generatedReportId,
                  reportGeneratedDateTime,
                  reportTypeId,
                }) => (
                  <Tr
                    key={generatedReportId}
                    _hover={{
                      backgroundColor: "gray.50",
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={onClickJob(reportTypeId as ReportType)}
                  >
                    <Td fontWeight={500}>
                      <Flex alignItems="center" width="250px">
                        <Image
                          key={generatedReportId}
                          src={PdficonImage}
                          width="40px"
                        />
                        <Flex ml={2} whiteSpace="break-spaces">
                          <Text>
                            {getReportTypeName(reportTypeId as ReportType)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td>
                      <Text fontWeight="medium">
                        {job?.projectAddressStreet}
                      </Text>
                      {job?.projectAddressCity || ""}{" "}
                      {job?.projectAddressState || ""}{" "}
                      {job?.projectAddressZip || ""}
                    </Td>
                    <Td>{reportGeneratedDateTime}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
};
